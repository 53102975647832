import React from "react";
import theme from "theme";
import { Theme, Text, Image, Section, Box, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | About – TireTopia
			</title>
			<meta name={"description"} content={"Discover the Road Less Ordinary - TireTopia, Where Your Journey Begins with Reliable Tires"} />
			<meta property={"og:title"} content={"About Us | About – TireTopia"} />
			<meta property={"og:description"} content={"Discover the Road Less Ordinary - TireTopia, Where Your Journey Begins with Reliable Tires"} />
			<meta property={"og:image"} content={"https://in-turolivera.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://in-turolivera.com/img/1590850.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://in-turolivera.com/img/1590850.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://in-turolivera.com/img/1590850.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://in-turolivera.com/img/1590850.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://in-turolivera.com/img/1590850.png"} />
			<meta name={"msapplication-TileImage"} content={"https://in-turolivera.com/img/1590850.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Images-23">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				max-width="none"
				width="100%"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--headline2"
				width="20%"
				text-align="right"
				lg-width="100%"
				lg-text-align="left"
				lg-font="--headline3"
				sm-margin="16px 0px 0px 0px"
				letter-spacing="2px"
				text-transform="uppercase"
			>
				About Us
			</Text>
			<Image
				src="https://in-turolivera.com/img/7.jpg"
				display="block"
				width="60%"
				lg-order="-1"
				sm-width="100%"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--lead"
				width="20%"
				align-self="flex-end"
				lg-width="100%"
				lg-max-width="540px"
				lg-align-self="auto"
			>
				TireTopia is more than just a wheel shop – it's a destination that understands the importance of the right set of tires for your vehicle. Established with a passion for enhancing driving experiences, TireTopia has become a hub for those who seek not only premium tires but also a reliable partner in their journey. We believe in the simplicity of providing top-quality tires to ensure your safety, comfort, and enjoyment on the road.
			</Text>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Our Journey
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Founded with a vision to redefine the tire-buying experience, TireTopia embarked on a mission to provide a curated selection of tires that align with diverse driving needs. From commuters to adventurers, every journey finds its perfect match at TireTopia. We've grown, adapted, and evolved, but our core values remain centered on reliability, expertise, and customer satisfaction.
					</Text>
				</Box>
				<Image src="https://in-turolivera.com/img/8.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://in-turolivera.com/img/9.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						At TireTopia, we acknowledge that the road isn't just a path – it's an adventure waiting to unfold. Our commitment goes beyond transactions – it's about building a community of drivers who share a common appreciation for the open road. While our story is woven into the fabric of our commitment to excellence, we prefer to let our tires do most of the talking.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Beyond Tires
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						TireTopia isn't just a shop – it's a resource for tire enthusiasts. Our tire care tips, industry insights, and efficient services are aimed at making your tire-related decisions informed and stress-free. We're not just here to sell tires – we're here to elevate your driving experience.
					</Text>
					<Button
						href="/contact"
						type="link"
						text-decoration-line="initial"
						background="#0438cd"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Join us on the road
					</Button>
				</Box>
				<Image src="https://in-turolivera.com/img/10.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});